const _defaultOptions = {
  triggers: '.js-readmore',
  class: 'is-truncated',
  onToggle: () => {},
};

class Readmore {
  constructor (options) {
    this.options = Object.assign({}, _defaultOptions, options);
    this.elements();
    this.events();
    return this;
  }

  handleToggle(target, trigger, evt) {
    evt.preventDefault();
    if (target) {
      if (target.classList.contains(this.options.class)) {
        target.classList.remove(this.options.class);
        trigger.firstElementChild.innerHTML =
          trigger.firstElementChild.innerHTML.replace(new RegExp('more', 'i'), 'less');
      } else {
        target.classList.add(this.options.class);
        trigger.firstElementChild.innerHTML =
          trigger.firstElementChild.innerHTML.replace(new RegExp('less', 'i'), 'more');
      }
    }
    this.options.onToggle.call();
  }

  elements () {
    this.$triggers = Array.prototype.slice.call(document.querySelectorAll(this.options.triggers));
  }

  events () {
    this.$triggers.forEach(($trigger, i) => {
      const $targetEl = document.getElementById($trigger.getAttribute('data-target'));
      $trigger.addEventListener('click', this.handleToggle.bind(this, $targetEl, $trigger));
    });
  }
}

module.exports = Readmore;
