import Swiper from 'swiper';
import Blazy from 'blazy';
import WebFont from 'webfontloader';
import Scroll from 'scroll-js';

import Site from './modules/site';
import Readmore from './modules/readmore';
import Menu from './modules/menu';
import Analytics from './modules/analytics';
import LightboxGallery from './modules/lightbox-gallery';
import LightboxVimeo from './modules/lightbox-vimeo';

// required for FontFaceObserver,
// imported here to make available globally
require('es6-promise').polyfill();

const isFirefox = (navigator.userAgent.toLowerCase().indexOf('firefox') !== -1);


/**
 * Scrolling
 * ------------
 */
const scroll = new Scroll(isFirefox ? document.documentElement : document.body);
const scrollTriggers = Array.prototype.slice.call(document.querySelectorAll('.js-scrollto'));
let i = scrollTriggers.length;
while (i--) {
  scrollTriggers[i].addEventListener('click', (evt) => {
    evt.preventDefault();

    let target = evt.currentTarget;
    let anchor = target.href || target.getAttribute('data-target') || null;
    let anchorParts = anchor.split('#');
    let id = anchorParts.pop();

    if (id) {
      id = id.replace('#', '');
      let el = document.getElementById(id);
      scroll.to(0, el.offsetTop, {
        duration: 800,
        easing: 'easeInOutQuad'
      });
    }

  });
}


/**
 * Lazy images
 * ------------
 */
const blazy = new Blazy({
  offset: 200,
  selector: '.blazy',
  successClass: 'is-loaded',
  separator: ',',
  error: (el, msg) => {
    console.log(msg);
  }
});


/**
 * Readmore functionality
 * ------------
 */
const readmore = new Readmore({
  onToggle: () => {
    blazy.revalidate();
  },
});


/**
 * Load fonts from Typescript
 * ------------
 */
WebFont.load({
  typekit: {
    async: true,
    id: 'pzr6juz',
  }
});


/**
 * Analytics
 * ------------
 */
const analytics = new Analytics(window.__analytics);


/**
 * Site init
 * ------------
 */
new Site({
  fonts: [
    'Soleil',
  ]
});


/**
 * Mobile menu
 * ------------
 */
const menu = new Menu();


/**
 * Gallery Lightbox
 * ------------
 */
const lightboxGallery = new LightboxGallery({
  scroll: scroll
});

/**
 * Vimeo Lightbox
 * ------------
 */
const lightboxVimeo = new LightboxVimeo();



/**
 * Homepage hero carousel slides
 * ------------
 */
const heroCarousel = new Swiper('.hero-carousel', {
  speed: 1000,
  autoplay: 6000,
  autoplayDisableOnInteraction: false,
  loop: true,
  preloadImages: false,
  lazyLoading: true,
  lazyLoadingInPrevNext: true,
  effect: 'fade',
});



/**
 * Keyboard navigation
 * ------------
 */
window.addEventListener('keyup', function (evt) {

  const code = evt.keyCode || null;
  const link = null;

  switch (code) {
    case 39: // right
      lightboxGallery.isOpen && lightboxGallery.next();
      break;

    case 37: // left;
      lightboxGallery.isOpen && lightboxGallery.prev();
      break;

    case 27: // esc
      lightboxGallery.isOpen && lightboxGallery.close();
      lightboxVimeo.isOpen && lightboxVimeo.close();
      break;

    default:
      break;
  }

});


/**
 * Konami code
 *
 */
const konami = function(f,a){document.onkeyup=function(e){/113302022928$/.test(a+=''+((e||self.event).keyCode-37))&&f()}};
konami(() => {
  document.body.classList.add('is-konami');
});
