export const TRACKING_VERSION = '1';

export default class Analytics {

  constructor(trackingId = null) {

    this.trackingId = trackingId || false;
    if (!this.trackingId) return this;

    // Initialize the command queue in case analytics.js hasn't loaded yet.
    window.ga = window.ga || ((...args) => (ga.q = ga.q || []).push(args));

    this.init();
    this.trackErrors();

    return this;

  }

  init() {

    const dimensions = {
      TRACKING_VERSION: 'dimension1',
      CLIENT_ID: 'dimension2',
      WINDOW_ID: 'dimension3',
    };

    ga('create', this.trackingId, 'auto');
    ga('set', 'transport', 'beacon');
    ga('send', 'pageview');

    ga('set', dimensions.WINDOW_ID, this.uuid());
    ga('set', dimensions.TRACKING_VERSION, TRACKING_VERSION);
    ga((tracker) => {
      tracker.set(dimensions.CLIENT_ID, tracker.get('clientId'));
    });

  }

  trackError(error, fieldsObj = {}) {
    ga('send', 'event', Object.assign({
      eventCategory: 'Script',
      eventAction: 'error',
      eventLabel: (error && error.stack) || '(not set)',
      nonInteraction: true,
    }, fieldsObj));
  };

  trackErrors() {

    const loadErrorEvents = window.__e && window.__e.q || [];
    const fieldsObj = {
      eventAction: 'Uncaught error'
    };

    // Replay any stored load error events.
    for (let event of loadErrorEvents) {
      this.trackError(event.error, fieldsObj);
    }

    // Add a new listener to track event immediately.
    window.addEventListener('error', (event) => {
      this.trackError(event.error, fieldsObj);
    });
  };

  uuid(a) {
    return a
      ? (a ^ Math.random() * 16 >> a / 4).toString(16)
      : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.uuid);
  }

};
