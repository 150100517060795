let _defaultOptions = {
  lightboxId: null,
  openClass: 'is-open'
};

class Lightbox {

  constructor(options) {
    this.options = Object.assign({}, _defaultOptions, options);
    this.$lightbox;
    this.$close;
    this.isOpen = false;

    if (!this._getElements()) return;

    this._events();

    return this;
  }

  open() {
    this.$lightbox.classList.add(this.options.openClass);
    this.isOpen = true;
  }

  close() {
    this.$lightbox.classList.remove(this.options.openClass);
    this.isOpen = false;
  }

  _handleLightboxClick(evt) {
    let target = evt.target;
    if (target === this.$lightboxImg || target == this.$lightboxCaption) {
      this.close();
    }
  }

  _getElements() {
    this.$lightbox = document.getElementById(this.options.lightboxId);
    if (!this.$lightbox) return false;
    this.$close = this.$lightbox.querySelector('#lightbox-close');
    return true;
  }

  _events() {
    this.$close.addEventListener('click', this.close.bind(this));
    this.$lightbox.addEventListener('click', this._handleLightboxClick.bind(this));
  }

}

module.exports = Lightbox;
