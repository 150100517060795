// import Hammer from 'hammerjs';
import Lightbox from './lightbox';

const VIMEO_IFRAME = '<iframe src="//player.vimeo.com/video/[[vimeoId]]?[[params]]" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

let _defaultOptions = {
  lightboxId: 'lightbox-vimeo',
  lightboxEmbedId: 'lightbox-embed',
  // lightboxFooterId: 'lightbox-footer',
  // lightboxFooterDateId: 'lightbox-date',
  // lightboxFooterCaptionId: 'lightbox-caption',
  videos: '.js-vimeo-lightbox',
  loadedClass: 'has-loaded',
  dataVimeoId: 'data-vimeo-id',
  hasLoadedClass: 'has-loaded',

  params: [
    'api=1',
    'title=0',
    'autoplay=1',
    'portrait=1',
    'byline=0',
    'badge=1'
  ],
};

class LightboxVideo extends Lightbox {

  constructor(options) {
    const superOptions = Object.assign({}, _defaultOptions, options);

    super(superOptions);

    this.$embed;
    this.$player;

    this._openFromHashIndex();

    return this;

  }

  close() {
    super.close();
    this.$lightbox.classList.remove(this.options.hasLoadedClass);
    this.$embed.innerHTML = '';
    if (history.pushState) {
      history.pushState(null, null, '#');
    } else {
      location.hash = '';
    }

  }

  hasLoaded() {
    this.$lightbox.classList.add(this.options.hasLoadedClass);
  }

  view(vimeoId = null) {

    const params = this.options.params.slice(0);

    params.push('player_id=' + new Date().getTime());

    const embedString = VIMEO_IFRAME
      .replace('[[vimeoId]]', vimeoId)
      .replace('[[params]]', params.join('&'));

    this.$embed.innerHTML = embedString;

    this.$player = this.$embed.querySelector('iframe');

    if (history.pushState) {
      history.pushState(null, null, `#${vimeoId}`);
    } else {
      location.hash = `#${vimeoId}`;
    }

    this.open();

  }

  _onVimeoMessage(evt) {
    // Handle messages from the vimeo player only
    if (!(/^https?:\/\/player.vimeo.com/).test(evt.origin)) {
      return false;
    }
    if (this.playerOrigin === '*') {
      this.playerOrigin = evt.origin;
    }
    var data = JSON.parse(evt.data);
    switch (data.event) {
      case 'ready':
        this.hasLoaded();
        break;
      case 'playProgress':
        break;
      case 'pause':
        break;
      case 'finish':
        break;
    }
  }

  _handleImageClick(evt) {

    evt.preventDefault();
    const target = evt.currentTarget;

    const vimeoId = target.getAttribute(this.options.dataVimeoId);

    if (!vimeoId) return;

    this.$lightbox.classList.remove(this.options.loadedClass);

    setTimeout(() => {
      this.view(vimeoId);
    }, 100);

  }

  _openFromHashIndex() {

    let hash = window.location.hash.replace('#', '');
    let video;

    if (!hash && this.isOpen) {
      this.close();
      return;
    } else if (!hash && !this.isOpen) {
      return;
    }

    for (let i = 0; i < this.$videos.length; i++) {
      const vimeoId = this.$videos[i].getAttribute(this.options.dataVimeoId) || '';
      if (vimeoId.indexOf(hash) !== -1) {
        video = this.$videos[i];
        break;
      }
    }

    if (video) {
      video.click();
    }

  }

  _getElements() {

    if (!super._getElements()) return false;

    this.$videos = Array.prototype.slice.call(document.querySelectorAll(this.options.videos));
    this.$embed = document.getElementById(this.options.lightboxEmbedId);

    return true;

  }

  _events() {

    super._events();

    let i = this.$videos.length;
    while (i--) {
      this.$videos[i].addEventListener('click', this._handleImageClick.bind(this));
    }

    window.addEventListener('message', this._onVimeoMessage.bind(this), false);

    if (window.history) {
      window.onpopstate = this._openFromHashIndex.bind(this);
    }

  }

}

module.exports = LightboxVideo;
