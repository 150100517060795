import FontFaceObserver from 'fontfaceobserver';

const _defaultOptions = {
  fonts: []
};

const CLASSES = {
  noTouch: 'no-touch',
  hasTouch: 'has-touch',
  noJs: 'no-js',
  hasJs: 'has-js',
  pendingFonts: 'pending-fonts',
  hasFonts: 'has-fonts',
  noFonts: 'no-fonts'
};

class Site {

  constructor (options) {

    options = options || {};

    this.options = Object.assign({}, _defaultOptions, options);

    this.path = window.location.origin + this.options.dir;
    this.ua = window.navigator.userAgent;
    this.platform = window.navigator.platform;

    this.hasTouch = false;

    this.$html = document.documentElement;

    this.detectTouch();
    this.setAttributes();
    this.setClasses();

    setTimeout(() => {
      this.fontFaceObserver();
    }, 10);

    return this;

  }

  detectTouch() {
    const setHasTouch = () => {
      this.$html.classList.add(CLASSES.hasTouch);
      this.$html.classList.remove(CLASSES.noTouch);
      window.removeEventListener('touchstart', setHasTouch);
    }
    window.addEventListener('touchstart', setHasTouch, false);
  }

  setAttributes () {

    this.$html.setAttribute('data-useragent', this.ua);
    this.$html.setAttribute('data-platform', this.platform);

  }

  setClasses () {

    this.$html.classList.remove(CLASSES.noJs);
    this.$html.classList.add(CLASSES.hasJs);

  }

  fontFaceObserver() {

    const observers = this.options.fonts.map(font => new FontFaceObserver(font).load().then((_font) => {
      this.$html.classList.add(`has-${_font.family.toLowerCase()}`);
    }));

    this.$html.classList.add(CLASSES.pendingFonts);
    this.$html.classList.remove(CLASSES.noFonts);

    Promise.all(observers).then(() => {
      this.$html.classList.remove(CLASSES.pendingFonts);
      this.$html.classList.add(CLASSES.hasFonts);
    }).catch(() => {
      this.$html.classList.remove(CLASSES.pendingFonts);
      this.$html.classList.add(CLASSES.noFonts);
    });
  }

}

export default Site;
