import Hammer from 'hammerjs';
import Lightbox from './lightbox';

let _defaultOptions = {
  lightboxId: 'lightbox-gallery',
  lightboxImgId: 'lightbox-img',
  lightboxFooterId: 'lightbox-footer',
  lightboxFooterDateId: 'lightbox-date',
  lightboxFooterCaptionId: 'lightbox-caption',
  images: '.js-lightbox',
  loadedClass: 'has-loaded',
  dataUrl: 'data-url',
};

class LightboxGallery extends Lightbox {

  constructor(options) {
    const superOptions = Object.assign({}, _defaultOptions, options);

    super(superOptions);

    this._openFromHashIndex();

    return this;

  }

  close() {
    super.close();
    if (history.pushState) {
      history.pushState(null, null, '#');
    } else {
      location.hash = '';
    }

  }

  view(img = {}) {

    this.currentIndex = this._findCurrentIndex(img.url);

    if (history.pushState) {
      history.pushState(null, null, `#${img.id}`);
    } else {
      location.hash = `#${img.id}`;
    }

    let currentImgs = this.$lightboxImg.querySelectorAll('img:not(.is-removing)');
    let imgsToRemove = this.$lightboxImg.querySelectorAll('img.is-removing');

    for (let i = 0; i < currentImgs.length; i++) {
      let currentImg = currentImgs[i];
      currentImg.classList.add('is-removing');
      currentImg.addEventListener('transitionend', (evt) => {
        if (evt.propertyName === 'opacity' && evt.target === currentImg) {
          evt.target.classList.add('is-removed');
        }
      });
    }

    for (let i = 0; i < imgsToRemove.length; i++) {
      imgsToRemove[i].parentNode.removeChild(imgsToRemove[i]);
    }

    this.newImage(img);

  }

  newImage(img = {}) {
    let imgEl = new Image();
    imgEl.onload = this._handleImageLoaded.bind(this, img);
    imgEl.src = img.url;

    this.$lightboxImg.appendChild(imgEl);
    this.$lightboxFooterCaption.innerHTML = img.caption;
    this.$lightboxFooterDate.innerHTML = img.date;

    this.hasCaption = img.caption || img.date;

    this._updateControls();
    this.open();

  }

  set hasCaption(caption) {
    caption = !!caption && caption.length > 40;
    this.$lightboxFooter.classList.toggle('can-truncate', caption);
  }

  next() {

    let current = this.currentIndex;
    let next = current + 1;

    if (this.$images[next]) {
      this.currentIndex = next;
      this.$images[next].click();
    }

  }

  prev() {

    let current = this.currentIndex;
    let prev = current - 1;

    if (this.$images[prev]) {
      this.currentIndex = prev;
      this.$images[prev].click();
    }

  }

  centerImageOnPage(el) {

    if (this.options.scroll) {
      el = el.offsetTop ? el : el.offsetParent;
      let elPosition = el.offsetTop;
      let elHeight = el.offsetHeight;
      let windowHeight = window.outerHeight;
      let centerElementScreenPosition = Math.round(elPosition - (windowHeight / 2) + (elHeight / 2));
      this.options.scroll.to(0, centerElementScreenPosition, {
        duration: 200,
        easing: 'easeOutQuad'
      });
    }

  }

  toggleTruncation() {
    this.$lightboxFooter.classList.toggle('is-truncated');
  }

  _updateControls() {

    if (this.currentIndex === 0) {
      this.$prev.disabled = true;
    } else {
      this.$prev.disabled = false;
    }

    if (this.currentIndex === this.$images.length - 1) {
      this.$next.disabled = true;
    } else {
      this.$next.disabled = false;
    }

  }

  _findCurrentIndex(url) {
    let i = 0;
    for (i; i < this.$images.length; i++) {
      let imgUrl = this.$images[i].getAttribute(this.options.dataUrl);
      if (imgUrl === url) {
        break;
      }
    }
    return i;
  }

  _handleImageLoaded(img = {}) {
    this.$lightbox.classList.add(this.options.loadedClass);
    setTimeout(() => {
      this.centerImageOnPage(img.el);
    }, 400);
  }

  _handlePage(direction = 'next') {
    if (typeof this[direction] === 'function') {
      this[direction].call(this);
    }
  }

  _handleImageClick(evt) {

    evt.preventDefault();
    let target = evt.currentTarget;

    let url = target.getAttribute(this.options.dataUrl);

    if (!url) return;

    this.$lightbox.classList.remove(this.options.loadedClass);

    setTimeout(() => {
      this.view({
        url: url,
        el: target,
        id: target.getAttribute('data-id'),
        date: target.getAttribute('data-date'),
        caption: target.getAttribute('data-caption')
      });
    }, 100);

  }

  _openFromHashIndex() {

    let hash = window.location.hash.replace('#', '');
    let img;

    if (!hash && this.isOpen) {
      this.close();
      return;
    } else if (!hash && !this.isOpen) {
      return;
    }

    for (let i = 0; i < this.$images.length; i++) {

      let imgUrl = this.$images[i].getAttribute(this.options.dataUrl) || '';

      if (imgUrl.indexOf(hash) !== -1) {
        img = this.$images[i];
        break;
      }
    }

    if (img) {
      img.click();
    }

  }

  _getElements() {

    if (!super._getElements()) return false;

    this.$images = Array.prototype.slice.call(document.querySelectorAll(this.options.images));

    this.$prev = document.getElementById('lightbox-prev');
    this.$next = document.getElementById('lightbox-next');

    this.$lightboxImg = document.getElementById(this.options.lightboxImgId);
    this.$lightboxFooter = document.getElementById(this.options.lightboxFooterId);
    this.$lightboxFooterDate = document.getElementById(this.options.lightboxFooterDateId);
    this.$lightboxFooterCaption = document.getElementById(this.options.lightboxFooterCaptionId);

    return true;

  }

  _events() {

    super._events();

    let i = this.$images.length;
    while (i--) {
      this.$images[i].addEventListener('click', this._handleImageClick.bind(this));
    }

    this.$prev.addEventListener('click', this._handlePage.bind(this, 'prev'));
    this.$next.addEventListener('click', this._handlePage.bind(this, 'next'));

    this.$lightboxFooter.addEventListener('click', this.toggleTruncation.bind(this));

    var hammertime = new Hammer(this.$lightboxImg);
    hammertime.on('swipeleft', this._handlePage.bind(this, 'next'));
    hammertime.on('swiperight', this._handlePage.bind(this, 'prev'));

    if (window.history) {
      window.onpopstate = this._openFromHashIndex.bind(this);
    }

  }

}

module.exports = LightboxGallery;
