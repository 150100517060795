const _defaultOptions = {
  menuId: 'nav-menu',
  openBtnId: 'open-menu',
  closeBtnId: 'close-menu',
  class: 'is-open'
};

class Menu {
  constructor (options) {
    this.options = Object.assign({}, _defaultOptions, options);
    this.$menu;
    this.$btnOpen;
    this.$btnClose;
    this._elements();
    if (!this.$menu || !this.$btnOpen) return;
    this._events();
    return this;
  }

  open () {
    this.$menu.classList.add(this.options.class);
  }

  close () {
    this.$menu.classList.remove(this.options.class);
  }

  toggle () {
    this.$menu.classList.toggle(this.options.class);
  }

  _handleToggle (evt) {
    evt.preventDefault();
    this.toggle();
  }

  _elements () {
    this.$menu = document.getElementById(this.options.menuId);
    this.$btnOpen = document.getElementById(this.options.openBtnId);
    this.$btnClose = document.getElementById(this.options.closeBtnId);
  }

  _events () {
    this.$btnOpen.addEventListener('click', this._handleToggle.bind(this));
    this.$btnClose.addEventListener('click', this.close.bind(this));
  }
}

module.exports = Menu;
